import React from 'react';
import {
  Page,
  Content,
  ContentHeader,
  ItemCardGrid,
} from '@backstage/core-components';
import { LeaderboardRankingTable } from '@efg/backstage-plugin-leaderboard';
import { CortexInsightCard } from '@efg/cortex-backstage-plugin';

export const LeaderboardPage = () => (
  <Page themeId="tool">
    <Content>
      <ContentHeader title="Technology leaderboard" />
      <ItemCardGrid>
        <CortexInsightCard />
      </ItemCardGrid>
      <LeaderboardRankingTable />
    </Content>
  </Page>
);
