import { createApiRef, FetchApi } from '@backstage/core-plugin-api';
import { TeamScore } from '@efg/backstage-plugin-leaderboard-common';

export interface LeaderboardApi {
  getRanking(): Promise<TeamScore[]>;
}

export const leaderboardApiRef = createApiRef<LeaderboardApi>({
  id: 'leaderboard.api',
});

export class DefaultLeaderboardApi implements LeaderboardApi {
  private readonly fetchApi: FetchApi;
  private readonly pluginId: string;

  constructor(options: { fetchApi: FetchApi }) {
    this.fetchApi = options.fetchApi;
    this.pluginId = 'leaderboard';
  }

  /**
   * Handles errors when talking to the backend plugin
   */
  private async handleResponse(response: Response): Promise<TeamScore[]> {
    if (!response.ok) {
      const payload = await response.text();
      let message;
      switch (response.status) {
        case 404:
          message = `Could not find the ${this.pluginId} Backend (HTTP 404). Make sure the plugin has been fully installed.`;
          break;
        default:
          message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      }
      throw new Error(message);
    }

    return (await response.json()) as TeamScore[];
  }

  async getRanking(): Promise<TeamScore[]> {
    const baseUrl = `plugin://${this.pluginId}/ranking`;

    const response = await this.fetchApi.fetch(baseUrl, {
      method: 'GET',
    });

    return this.handleResponse(response);
  }
}
