import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { DefaultLeaderboardApi, leaderboardApiRef } from './api/LeaderboardApi';

export const leaderBoardApiFactory = createApiFactory({
  api: leaderboardApiRef,
  deps: {
    fetchApi: fetchApiRef,
  },
  factory: ({ fetchApi }) =>
    new DefaultLeaderboardApi({
      fetchApi: fetchApi,
    }),
});

export const leaderboardPlugin = createPlugin({
  featureFlags: [{ name: 'leaderboard' }],
  apis: [leaderBoardApiFactory],
  id: 'leaderboard',
  routes: {
    root: rootRouteRef,
  },
});

/*
 * A Page mainly used for testing, we want to add more element in the page from other plugins
 * But making our plugins depend on other plugins make the routing a little harder
 */
export const LeaderboardPageBlueprint = leaderboardPlugin.provide(
  createRoutableExtension({
    name: 'LeaderboardPageBlueprint',
    component: () =>
      import('./components/LeaderboardPage').then(
        m => m.LeaderboardPageBlueprint,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const LeaderboardRankingTable = leaderboardPlugin.provide(
  createComponentExtension({
    name: 'LeaderboardRankingTable',
    component: {
      lazy: () =>
        import('./components/LeaderboardPage').then(m => m.RankingTable),
    },
  }),
);

export const LeaderboardTeamScoreCard = leaderboardPlugin.provide(
  createComponentExtension({
    name: 'LeaderboardTeamScoreCard',
    component: {
      lazy: () =>
        import('./components/EntityScoresCard').then(m => m.TeamScoreCard),
    },
  }),
);
